import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogueSelectControl = _resolveComponent("CatalogueSelectControl")!
  const _component_CreateEditDialog = _resolveComponent("CreateEditDialog")!

  return (_openBlock(), _createBlock(_component_CreateEditDialog, {
    saveModelCallback: _ctx.saveModelCallback,
    ref: "dialog"
  }, {
    editForm: _withCtx(({ model, validationGroup }) => [
      _createVNode(_component_CatalogueSelectControl, {
        id: model.roleId,
        "onUpdate:id": ($event: any) => ((model.roleId) = $event),
        desc: model.roleDescription,
        "onUpdate:desc": ($event: any) => ((model.roleDescription) = $event),
        keyField: "id",
        displayDescField: "name",
        catalogueComponentName: "catalogues/RoleCatalogue/RoleCatalogue",
        validationGroup: validationGroup
      }, null, 8, ["id", "onUpdate:id", "desc", "onUpdate:desc", "validationGroup"])
    ]),
    _: 1
  }, 8, ["saveModelCallback"]))
}